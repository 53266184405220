.body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 20px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 15px;
}

.container {
    max-width: 800px;
    margin: auto;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.disclaimer {
    margin-top: 30px;
    font-style: italic;
    color: #888;
}
.back{
    width: 100%;
    position: relative;


}
.back-arrow{
    width: fit-content;
    cursor: pointer;
    position: relative;
     
    bottom: 0%;

}