* {

  overflow-x: hidden;
}

p {
  margin: 0;
}

.App-header {
  background-color: #282c34;
  max-width: 100vw;
  height: 100vh;

  color: white;
}

.song-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 95vh;

}

.song-box {
  max-width: 300px;
  padding: 10px;
  background-color: hsl(217, 6%, 26%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

}

.song-box img {
  border-radius: 12px;
}

.song-box button {
  color: white;
  border: 1px solid #282c34;
  background-color: #60646b;
  width: 100%;
  margin: auto;
  height: 8vh;
  margin: 10px 0px 10px 0px;

}

.song-info {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: medium;

}

.song-name {
  font-size: larger;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.back-arrow {
  padding: 5px 10px;
  font-weight: 500;
}

.link.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  width: 600px;
  align-items: center;
  justify-content: center;

}


.link.box input {
  width: 100%;
  height: 4vh;
  background-color: white;
  border: 1px #263a61 solid;

}

.link.box input:focus {

  background-color: aqua;
  border: 1px #282c34 solid;
  outline: none;

}

.link.box div.input {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.guide {
  font-size: large;
}


.body-loder {
  margin: 0;
  padding: 0;
  position: absolute;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
  backdrop-filter: blur(5px);
  /* Optional background color */
}

.loader {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 8px solid #ccc;
  /* Border color */
  border-top-color: #3498db;
  /* Loading wheel color */
  animation: spin 1s linear infinite;
  position: relative;
}

.inner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 639px) {
  h1 {
    font-size: 1.6rem;
  }

  .guide {
    align-items: start;

    font-size: 0.9em;
  }

  ol {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
    align-items: start;
    justify-content: start;
  }

  li {
    align-items: start;
    justify-content: start;
    text-align: start;
    font-size: 0.8em;
  }


  .link.box div.input {
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: medium;
  }

  .link.box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;
    width: 80vw;
    align-items: center;
    justify-content: center;

  }

}

.vontainer {
  display: flex;
  padding: 28px 128px;
  text-align: start;

}

 